/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, bool, object, func,
} from 'prop-types';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button } from 'mbp-components';
// redux
import { useSelector } from 'react-redux';
import { getBrandName, getBrandIdentifier } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
// helpers
import cssVariablesEnvDir from '../helpers/cssVariablesEnvDir';
import useStyleLinkTag from '../StyleLinkContext/useStyleLinkTag';
// css
import buttonStyles from './styles/button.module.css';

/**
 * Enterprise Design System button template.
 * @component
 * @param {string} label - The text shown in the button.
 * @param {string} link - Where should it go?
 * @param {string} variant - "primary", "secondary" or "text-only".
 * @param {string} size - "sm", "md", "lg".
 * @param {string} type - "button", "submit" or "reset" .
 * @param {bool} disabled - true/false (default).
 * @param {bool} fullWidth - true/false (default).
 * @param {bool} isInsideClickableLink - Is this button nested inside a larger clickable feature? true/false (default).
 * @param {object} icon - Pass the icon value, if any.
 * @param {object} attributes - e.g. {'data-testid="addToCartButton"}.
 * @param {func} handleOnClick - Pass a function that you wish to fire onClick.
*/

// Apply and track CSS
const StyledButton = ({
    label, link, variant, size, type, disabled, fullWidth, isInsideClickableLink, icon, handleOnClick, attributes,
}) => {
    // SSR injection and CSR hydration
    useStyles(buttonStyles);
    const brandDomainName = useSelector(getBrandName);
    const brandIdentifier = useSelector(getBrandIdentifier);
    useStyleLinkTag(`https://cdn1.${brandDomainName}.com/wcsstore/${brandIdentifier}/css/${cssVariablesEnvDir}/components/button/button.vars.css`);

    return (
        <>
            <Helmet>
                <link rel="stylesheet" href={`https://cdn1.${brandDomainName}.com/wcsstore/${brandIdentifier}/css/${cssVariablesEnvDir}/components/button/button.vars.css`} />
            </Helmet>
            <Button
                styles={buttonStyles}
                icon={icon}
                reactRouterLinkComponent={Link}
                label={label}
                link={link}
                variant={variant}
                size={size}
                type={type}
                disabled={disabled}
                fullWidth={fullWidth}
                isInsideClickableLink={isInsideClickableLink}
                handleOnClick={handleOnClick}
                attributes={attributes && typeof attributes === 'string' ? JSON?.parse(attributes) : attributes}
            />
        </>
    );
};

StyledButton.propTypes = {
    label: string.isRequired,
    link: string,
    variant: string,
    size: string,
    type: string,
    disabled: bool,
    fullWidth: bool,
    isInsideClickableLink: bool,
    icon: object,
    handleOnClick: func,
    attributes: object,
};

StyledButton.defaultProps = {
    link: '',
    variant: 'primary',
    size: 'sm',
    type: 'button',
    disabled: false,
    fullWidth: false,
    isInsideClickableLink: false,
    icon: {},
    handleOnClick: () => {},
    attributes: {},
};

export default StyledButton;
